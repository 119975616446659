import { Label, StandardInput } from '@loveholidays/design-system';
import { TFunction, useTranslation } from '@loveholidays/phrasebook';
import React, { Fragment, useState } from 'react';

export const LIST_NAME_MIN_LENGTH = 1;
export const LIST_NAME_MAX_LENGTH = 100;

export const getErrorMessage = (t: TFunction, text: string) => {
  if (text.length < LIST_NAME_MIN_LENGTH) {
    return t('forms.atLeastXLong', { min: LIST_NAME_MIN_LENGTH });
  }
  if (text.length > LIST_NAME_MAX_LENGTH) {
    return t('forms.maximumXLong', { max: LIST_NAME_MAX_LENGTH });
  }

  return;
};

interface ListNameInputProps {
  labelTitle: string;
  title?: string;
  onChange?: ({ title, isValid }: { title: string; isValid: boolean }) => void;
}

export const ListNameInput: React.FC<ListNameInputProps> = ({
  title = '',
  labelTitle,
  onChange = () => {},
}) => {
  const [isValid, setIsValid] = useState(true);
  const { t } = useTranslation();

  return (
    <Fragment>
      <StandardInput
        label={labelTitle}
        variant="64"
        value={title}
        onChange={(e) => {
          setIsValid(e.target.checkValidity());
          onChange({
            title: e.target.value.trim(),
            isValid: e.target.checkValidity(),
          });
        }}
        minLength={LIST_NAME_MIN_LENGTH}
        maxLength={LIST_NAME_MAX_LENGTH}
        required
        errorMessage={getErrorMessage(t, title)}
      />

      {isValid && (
        <Label
          as="p"
          variant="small"
          sx={{
            marginTop: '2xs',
            color: 'textDimmedmedium',
          }}
        >
          {t('forms.xCharactersMaximum', { max: LIST_NAME_MAX_LENGTH })}
        </Label>
      )}
    </Fragment>
  );
};
