import { TriggerButton } from '@loveholidays/design-system';
import React, { Fragment, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { SxStyleProp } from 'theme-ui';
import { useClient } from 'urql';

import { FavouritesListItem } from './FavouritesListItem';
import { FavouriteNewList } from '../FavouriteNewList';
import addFavouriteToList from '../graphql/addFavouriteToList.gql';
import removeFavouriteFromList from '../graphql/removeFavouriteFromList.gql';
import { useUnauthenticatedRedirect } from '../useUnauthenticatedRedirect';
import { isUnauthenticatedError } from '../utils';
import { Mutation } from '@AuroraTypes';
import { getPageTypeClientSync } from '@Contexts/contexts';
import { useFavouritesStore } from '@Stores/StoreContext';

const listItemWrapperStyles: SxStyleProp = {
  width: '100%',
  marginBottom: 's',
  '&:last-of-type': {
    marginBottom: 0,
  },
};

export interface SelectFavouritesListProps {
  masterId: number;
  onCreateFavourites: () => void;
}

export const SelectFavouritesList: React.FC<SelectFavouritesListProps> = ({
  masterId,
  onCreateFavourites,
}) => {
  const urqlClient = useClient();
  const { pathname } = useLocation();
  const pageType = getPageTypeClientSync(pathname);
  const redirectToLoginPage = useUnauthenticatedRedirect();
  const [favouritesLists, addHotelToFavouritesList, removeHotelFromFavouritesList] =
    useFavouritesStore((state) => [
      state.favouritesLists,
      state.addHotelToFavouritesList,
      state.removeHotelFromFavouritesList,
    ]);

  const handleAddHotelToList = useCallback(
    async (listId: string) => {
      const { data, error } = await urqlClient
        .mutation<Mutation>(addFavouriteToList, {
          masterId,
          listId,
          source: pageType,
        })
        .toPromise();

      const addFavouriteToListResult = data?.User.addFavouriteToListV2;
      if (error || !addFavouriteToListResult) {
        throw new Error('Failed to add favourite to list');
      }
      if (isUnauthenticatedError(addFavouriteToListResult)) {
        return redirectToLoginPage();
      }

      addHotelToFavouritesList(masterId, listId);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [masterId],
  );

  const handleRemoveHotelFromList = useCallback(
    async (listId: string) => {
      const { data, error } = await urqlClient
        .mutation<Mutation>(removeFavouriteFromList, {
          masterId,
          listId,
          source: pageType,
        })
        .toPromise();

      const removeFavouriteFromListResult = data?.User.removeFavouriteFromListV2;
      if (error || !removeFavouriteFromListResult) {
        throw new Error('Failed to remove favourite from list');
      }
      if (isUnauthenticatedError(removeFavouriteFromListResult)) {
        return redirectToLoginPage();
      }

      removeHotelFromFavouritesList(masterId, listId);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [masterId],
  );

  return (
    <Fragment>
      <TriggerButton
        onTrigger={onCreateFavourites}
        sx={{
          ...listItemWrapperStyles,
          outline: 'none',
        }}
      >
        <FavouriteNewList compact />
      </TriggerButton>

      {favouritesLists.map((list) => {
        const isHotelInList = list.items.includes(masterId);

        return (
          <FavouritesListItem
            key={`${list.id}${isHotelInList}`}
            list={list}
            isHotelInList={isHotelInList}
            wrapperStyles={listItemWrapperStyles}
            handleAddHotelToList={handleAddHotelToList}
            handleRemoveHotelFromList={handleRemoveHotelFromList}
          />
        );
      })}
    </Fragment>
  );
};
