import { Check, TriggerButton } from '@loveholidays/design-system';
import { SystemStyleObject } from '@styled-system/css';
import React, { useState } from 'react';

import { FavouritesList } from '../FavouritesList';
import { FavouritesList as FavouritesListType } from '../interfaces';

interface FavouritesListItemProps {
  list: FavouritesListType;
  isHotelInList: boolean;
  wrapperStyles: SystemStyleObject;
  handleAddHotelToList: (listId: string) => Promise<void>;
  handleRemoveHotelFromList: (listId: string) => Promise<void>;
}

export const FavouritesListItem: React.FC<FavouritesListItemProps> = ({
  list,
  isHotelInList,
  wrapperStyles,
  handleAddHotelToList,
  handleRemoveHotelFromList,
}) => {
  const [isUpdating, setIsUpdating] = useState(false);

  const checked = isUpdating ? !isHotelInList : isHotelInList;

  const handleUpdateListItem = async () => {
    setIsUpdating(true);

    try {
      if (isHotelInList) {
        await handleRemoveHotelFromList(list.id);
      } else {
        await handleAddHotelToList(list.id);
      }
    } catch (e) {
      throw e;
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <TriggerButton
      disabled={isUpdating}
      onTrigger={handleUpdateListItem}
      sx={{
        ...wrapperStyles,
        display: 'flex',
        outline: 'none',
        alignItems: 'center',
      }}
    >
      <FavouritesList
        list={list}
        compact
      />
      <Check
        onChange={() => {}}
        checked={checked}
      />
    </TriggerButton>
  );
};
